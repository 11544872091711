import React from "react";
import { Link } from "react-router-dom";
import "./MLocation.css";

function MLocation(props) {
  const {
    aerialOverlay,
    spanText1,
    spanText2,
    spanText3,
    learningToLiveWit,
    thePhysicalStructu,
    personalPlacesToMeditateAndConnect,
    aPlaceToCreateE,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="m-location roboto-normal-black-14px screen">
        <Link to="/m-landing">
          <img className="vector-6" src="/img/vector@2x.svg" />
        </Link>
        <Link to="/m-landing" className="align-self-flex-center">
          <div className="pictures-6 animate-enter6">
            <img className="aerial-overlay-1" src={aerialOverlay} />
          </div>
        </Link>
        <p className="the-culture-and-desi">
          <span className="roboto-normal-black-14px">{spanText1}</span>
          <span className="roboto-normal-black-14px">{spanText2}</span>
          <span className="roboto-normal-black-14px">{spanText3}</span>
        </p>
        <p className="learning-to-live-wit">{learningToLiveWit}</p>
        <p className="the-physical-structu">{thePhysicalStructu}</p>
        <p className="personal-places-to-meditate-and-connect">{personalPlacesToMeditateAndConnect}</p>
        <p className="a-place-to-create-e">{aPlaceToCreateE}</p>
      </div>
    </div>
  );
}

export default MLocation;
