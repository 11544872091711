import React from "react";
import { Link } from "react-router-dom";
import "./MLanding.css";

function MLanding(props) {
  const { postPeyote, dji_00981, casita, grandmotherstars, aerialOverlay, image1 } = props;

  return (
    <div className="container-center-horizontal">
      <div className="m-landing screen">
        <div className="pictures animate-enter">
          <div className="overlap-group1">
            <Link to="/m-tribe">
              <img className="link" src={postPeyote} />
            </Link>
            <Link to="/m-naturalsetting">
              <img className="dji_0098-1" src={dji_00981} />
            </Link>
          </div>
          <Link to="/m-facilities">
            <img className="casita" src={casita} />
          </Link>
          <div className="overlap-group">
            {/* <Link to="/m-ceremonies"> */}
            <Link to="/events">
              <img className="link" src={grandmotherstars} />
            </Link>
            <Link to="/m-location">
              <img className="aerial-overlay" src={aerialOverlay} />
            </Link>
          </div>
          <Link to="/m-laventana">
            <img className="image-1" src={image1} />
          </Link>
        </div>
        <img className="vector" src="/img/vector@2x.svg" />
      </div>
    </div>
  );
}

export default MLanding;
