import React from "react";
import "./Mobile.css";

function Mobile(props) {
  const { postpeyote, grandmotherstars, casita, sunrise } = props;

  return (
    <div className="container-center-horizontal">
      <div className="mobile screen">
        <img className="frame-1" src="/img/frame-1@2x.svg" />
        <div className="pictures-7">
          <img className="post-peyote-1" src={postpeyote} />
          <img className="grandmother-stars-1" src={grandmotherstars} />
          <img className="casita-2" src={casita} />
          <img className="sunrise" src={sunrise} />
        </div>
      </div>
    </div>
  );
}

export default Mobile;
