import React from "react";
import { Link } from "react-router-dom";
import Pictures from "../Pictures";
import "./MFacilities.css";

function MFacilities(props) {
  const {
    img_05191,
    img_04811,
    theSmallCasitaOn,
    x39104F07335E4C22B5E50Ad841Df9C931,
    anRvWithAccomodat,
    additionalAccomodat,
    kiteventana,
    casaVistaCerralvo,
    picturesProps,
  } = props;

  return (
    <div className="container-center-horizontal">
      <div className="m-facilities screen">
        <Link to="/m-landing">
          <img className="vector-4" src="/img/vector@2x.svg" />
        </Link>
        <Pictures src={picturesProps.src} />
        <div className="img_-container">
          <img className="img_0519-1" src={img_05191} />
          <img className="img_0481-1" src={img_04811} />
        </div>
        <div className="overlap-group-3">
          <p className="the-small-casita-on roboto-normal-black-14px">{theSmallCasitaOn}</p>
          <img className="x39104-f07-335-e-4-c2" src={x39104F07335E4C22B5E50Ad841Df9C931} />
        </div>
        <p className="an-rv-with-accomodat roboto-normal-black-14px">{anRvWithAccomodat}</p>
        <div className="overlap-group1-2">
          <p className="additional-accomodat inter-normal-black-12px">{additionalAccomodat}</p>
          <a href="kiteventana.com" target="_blank">
            <div className="kite-ventana inter-normal-black-12px">{kiteventana}</div>
          </a>
          <a href="https://casavistacerralvo.com" target="_blank">
            <div className="casa-vista-cerralvo inter-normal-black-12px">{casaVistaCerralvo}</div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default MFacilities;
