import React from "react";
import { Link } from "react-router-dom";
import "./MCeremonies.css";

function MCeremonies(props) {
  const { grandmotherstars, outdoorCeremoniesA, theMildClimateIt, l10505091 } = props;

  return (
    <div className="container-center-horizontal">
      <div className="m-ceremonies screen">
        <Link to="/m-landing">
          <img className="vector-5" src="/img/vector@2x.svg" />
        </Link>
        <Link to="/m-landing">
          <div className="pictures-5 animate-enter5">
            <img className="grandmother-stars" src={grandmotherstars} />
          </div>
        </Link>
        <div className="overlap-group-4 roboto-normal-black-14px">
          <p className="outdoor-ceremonies-a">{outdoorCeremoniesA}</p>
          <p className="the-mild-climate-it">{theMildClimateIt}</p>
          <img className="l1050509-1" src={l10505091} />
        </div>
      </div>
    </div>
  );
}

export default MCeremonies;
