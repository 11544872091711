import React from "react";
import { Link } from "react-router-dom";
import "./MTribe.css";

function MTribe(props) {
  const { postPeyote, spanText1, spanText2, image5, image7, image8 } = props;

  return (
    <div className="container-center-horizontal">
      <div className="m-tribe screen">
        <Link to="/m-landing">
          <img className="vector-1" src="/img/vector@2x.svg" />
        </Link>
        <Link to="/m-landing">
          <div className="pictures-1 animate-enter1">
            <img className="post-peyote" src={postPeyote} />
          </div>
        </Link>
        <p className="tribu-provides-a-nut roboto-normal-black-14px">
          <span className="roboto-normal-black-14px">{spanText1}</span>
          <span className="roboto-normal-black-14px">{spanText2}</span>
        </p>
        <img className="image-5" src={image5} />
        <img className="image-7" src={image7} />
        <img className="image-8" src={image8} />
      </div>
    </div>
  );
}

export default MTribe;
