import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./MNaturalSetting.css";

function MNaturalSetting(props) {
  const {
    dji_00981,
    spanText1,
    spanText2,
    image9,
    img_41271,
    atTheBaseOfTheC,
    theDesertClimateI,
    sunrise,
    img_48101,
    spanText3,
    spanText4,
    spanText5,
    dsc_92741,
  } = props;

  useEffect(() => {
    var image = document.querySelectorAll(".sunrise");
    new window.simpleParallax(image, {
      orientation: "up",
      scale: 1.2,
      overflow: false,
      delay: 0.4,
      transition: "none",
    });
  }, []);

  return (
    <div className="container-center-horizontal">
      <div className="m-naturalsetting roboto-normal-black-14px screen">
        <Link to="/m-landing">
          <img className="vector-3" src="/img/vector@2x.svg" />
        </Link>
        <Link to="/m-landing">
          <div className="pictures-3 animate-enter3">
            <img className="dji_0098-1-1" src={dji_00981} />
          </div>
        </Link>
        <p className="tribu-is-located-on-1">
          <span className="roboto-normal-black-14px">{spanText1}</span>
          <span className="roboto-normal-black-14px">{spanText2}</span>
        </p>
        <img className="image-9" src={image9} />
        <div className="overlap-group1-1">
          <img className="img_4127-1" src={img_41271} />
          <p className="at-the-base-of-the-c roboto-normal-black-14px">{atTheBaseOfTheC}</p>
        </div>
        <p className="the-desert-climate-i">{theDesertClimateI}</p>
        <div className="widget-wrapper">
          <img className="sunrise" src={sunrise} />
        </div>
        <div className="overlap-group-2">
          <img className="img_4810-1" src={img_48101} />
          <p className="every-sunrise-and-su roboto-normal-black-14px">
            <span className="roboto-normal-black-14px">{spanText3}</span>
            <span className="roboto-normal-black-14px">{spanText4}</span>
            <span className="roboto-normal-black-14px">{spanText5}</span>
          </p>
        </div>
        <img className="dsc_9274-1" src={dsc_92741} />
      </div>
    </div>
  );
}

export default MNaturalSetting;
