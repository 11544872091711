import "./App.css";
import React from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import MLanding from "./components/MLanding";
import MTribe from "./components/MTribe";
import MLaVentana from "./components/MLaVentana";
import MNaturalSetting from "./components/MNaturalSetting";
import MFacilities from "./components/MFacilities";
import MCeremonies from "./components/MCeremonies";
import MLocation from "./components/MLocation";
import Sunrise from "./components/Sunrise";
import Mobile from "./components/Mobile";
// import "/events/index.html"

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/:path(|m-landing)">
          <MLanding
            postPeyote="/img/post-peyote@2x.png"
            dji_00981="/img/dji-0098-1@2x.png"
            casita="/img/casita@2x.png"
            grandmotherstars="/img/grandmotherstars@2x.png"
            aerialOverlay="/img/aerial-overlay@2x.png"
            image1="/img/image-1@2x.png"
          />
        </Route>
        <Route path="/m-tribe">
          <MTribe
            postPeyote="/img/post-peyote@2x.png"
            spanText1="Tribu"
            spanText2=" provides a nuturing, safe environment for emotional, spiritual, and physical healing."
            image5="/img/image-5@2x.png"
            image7="/img/image-7@2x.png"
            image8="/img/image-8@2x.png"
          />
        </Route>
        <Route path="/m-laventana">
          <MLaVentana
            pictures="/img/image-1-1@2x.png"
            spanText1="Tribu "
            spanText2="is located on the Gulf of California about 2.5 hours north of Cabo San Jose, and 50 minutes east of La Paz."
            theGulfOfCaliforn={
              <>
                The Gulf of California was described by Jaques Cousteau as the &quot;Aquarium of the world.” The
                abundance of marine life, including whales, mobula rays, coral reef and fishing attracts paddleboarders,
                sea kayakers, sailors, scuba divers, fishermen, marine biologists and ecotourists from all over the
                world. <br />
                <br />
                The nearby town of La Ventana is a top 10 global kitesurfing destination. During the winter months, the
                steady and predictable northern winds and calm ocean attract kite surfers from the US, Canada and around
                the world. <br /> <br />
                La Ventana is a quiet contrast to the bustling luxury resorts of Cabo San Lucas. The restaurants, cafes
                and grocery stores on the single main road serve fresh seafood, coffee and bread from local sources.
                Yoga and other spiritual practices are hosted at retreat centers such as Casa Tara and Baja Joe&#x27;s.
              </>
            }
          />
        </Route>
        <Route path="/m-naturalsetting">
          <MNaturalSetting {...mNaturalSettingData} />
        </Route>
        <Route path="/m-facilities">
          <MFacilities {...mFacilitiesData} />
        </Route>
        <Route path="/m-ceremonies">
          <MCeremonies
            grandmotherstars="/img/grandmotherstars@2x.png"
            outdoorCeremoniesA="Outdoor ceremonies are held on a small clearing near the front of the property."
            theMildClimateIt={
              <>
                {" "}
                <br />
                <br />
                The mild climate, its clear views of sunrise and moonrise, proximity to the ocean and open yet private
                setting make it ideal for sunset, sunrise and overnight settings.
              </>
            }
            l10505091="/img/l1050509-1@2x.png"
          />
        </Route>
        <Route path="/m-location">
          <MLocation {...mLocationData} />
        </Route>
        <Route path="/sunrise">
          <Sunrise />
        </Route>
        <Route path="/mobile">
          <Mobile
            postpeyote="/img/postpeyote@2x.png"
            grandmotherstars="/img/grandmotherstars@2x.png"
            casita="/img/casita@2x.png"
            sunrise="/img/sunrise@2x.png"
          />
        </Route>
        {/* Add a route that redirects to the notion page
        use window.location.replace so that user will navigate back to home page */}
        {/* <Route path="/events" render={() => {window.location.replace("https://thin-taker-e5e.notion.site/Upcoming-Events-e50a90de7e6e4b4695dca106dac7ddac")}}/>   */}
        {/* can also redirect to a local static page - the directory should be imported at the top for build to include it */}
        {/* <Route path="/upcoming-events" render={() => {window.location.replace("/upcoming-events/index.html")}}/> */}
        <Route path="/events" render={() => {window.location.replace("events/index.html")}}/>
      </Switch>
    </Router>
  );
}

export default App;
const picturesData = {
    src: "/img/casita@2x.png",
};

const mFacilitiesData = {
    img_05191: "/img/img-0519-1@2x.png",
    img_04811: "/img/img-0481-1@2x.png",
    theSmallCasitaOn: "The small casita on the property is used to prepare and serve meals, rest and recovery, and for work and sleep.",
    x39104F07335E4C22B5E50Ad841Df9C931: "/img/39104f07-335e-4c22-b5e5-0ad841df9c93-1@2x.png",
    anRvWithAccomodat: "An RV with accomodations for two and two 5 meter glamping tents are also onsite",
    additionalAccomodat: "Additional accomodations can be found at:",
    kiteventana: "KiteVentana",
    casaVistaCerralvo: "Casa Vista Cerralvo",
    picturesProps: picturesData,
};

const mNaturalSettingData = {
    dji_00981: "/img/dji-0098-1@2x.png",
    spanText1: "Tribu ",
    spanText2: "is located on a desert cliff overlooking the Gulf of California.",
    image9: "/img/image-9@2x.png",
    img_41271: "/img/img-4127-1@2x.png",
    atTheBaseOfTheC: "At the base of the cliff are hot springs and coral reef",
    theDesertClimateI: "The desert climate in Baja California Sur is warm and dry for ten months of the year.  Hurricanes arrive in August and September",
    sunrise: "/img/sunrise@2x.png",
    img_48101: "/img/img-4810-1@2x.png",
    spanText3: "Every sunrise and sunset at ",
    spanText4: "Tribu",
    spanText5: " is unique",
    dsc_92741: "/img/dsc-9274-1@2x.png",
};

const mLocationData = {
    aerialOverlay: "/img/aerial-overlay@2x.png",
    spanText1: "The culture and design of",
    spanText2: " Tribu ",
    spanText3: "are oriented around several guiding principles",
    learningToLiveWit: "Learning to live with oneself and others",
    thePhysicalStructu: "The physical structures help connect us to nature",
    personalPlacesToMeditateAndConnect: "Personal places to meditate and connect",
    aPlaceToCreateE: "A place to create, explore and shift boundaries",
};

