import React, { useEffect } from "react";
import "./Sunrise.css";

function Sunrise() {
  useEffect(() => {
    var image = document.querySelectorAll(".sunrise");
    new window.simpleParallax(image, {
      orientation: "up",
      scale: 1.2,
      overflow: false,
      delay: 0.4,
      transition: "none",
    });
  }, []);

  return (
    <div className="container-center-horizontal">
      <div className="sunrise screen"></div>
    </div>
  );
}

export default Sunrise;
