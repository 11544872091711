import React from "react";
import { Link } from "react-router-dom";
import "./MLaVentana.css";

function MLaVentana(props) {
  const { pictures, spanText1, spanText2, theGulfOfCaliforn } = props;

  return (
    <div className="container-center-horizontal">
      <div className="m-laventana screen">
        <Link to="/m-landing">
          <img className="vector-2" src="/img/vector@2x.svg" />
        </Link>
        <Link to="/m-landing">
          <div className="pictures-2 animate-enter2" style={{ backgroundImage: `url(${pictures})` }}></div>
        </Link>
        <div className="overlap-group-1 roboto-normal-black-14px">
          <p className="tribu-is-located-on">
            <span className="roboto-normal-black-14px">{spanText1}</span>
            <span className="roboto-normal-black-14px">{spanText2}</span>
          </p>
          <p className="the-gulf-of-californ">{theGulfOfCaliforn}</p>
        </div>
      </div>
    </div>
  );
}

export default MLaVentana;
