import React from "react";
import "./Pictures.css";

function Pictures(props) {
  const { src } = props;

  return (
    <div className="pictures-4 animate-enter4">
      <img className="casita-1" src={src} />
    </div>
  );
}

export default Pictures;
